img {
  height: 250px;
  width: 100%;
  object-fit: contain;
}

.arrow-block {
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;

  svg {
    width: 50px;
    height: 2em;
  }
}
