@import '../../App.scss';

.road-map-block {
  background: rgba(0, 0, 0, 0.1);
  border-top: 2px solid #d0cece;
}

.roadmap-container {
  font-family: 'Open Sans', sans-serif;
  position: relative;
  width: 100%;
  max-width: 1200px;
}

.stage-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.stage-details {
  font-size: 14px;
  width: 400px;
  height: 130px;

  h5 {
    font-size: 22px;
    margin-bottom: 15px;
  }

  ul {
    margin-left: 20px;
  }
}

li {
  margin-bottom: 5px;
  display: flex;
  align-items: flex-start;
  text-align: start;
  font-family: 'Montserrat', sans-serif;
  color: gray;
}

li svg {
  margin-right: 5px;
  margin-top: 4px;
  color: var(--main-color);
}

.roadmap-stage {
  position: relative;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;

  &:not(:last-child)::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50px;
    transform: translateX(-50%);
    width: 2px;
    height: 160px;
    background-color: var(--main-color);
  }

  h3 {
    text-align: center;
    width: 100%;
    font-size: 28px;
    font-family: 'Lobster', cursive;
    color: var(--main-color);

    svg {
      margin-right: 15px;
    }
  }
}

.left {
  align-items: flex-start;
}

.right {
  align-items: flex-end;
}

b {
  font-weight: 900 !important;
  font-style: normal;
  color: var(--main-color);
}

@media (max-width: 700px) {
  .roadmap-stage {
    &:not(:last-child)::after {
      height: 50px;
      top: 160px;
    }
  }
}
