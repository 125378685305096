:root {
  --main-color: #534391;
  --main-color-light: rgb(91, 74, 169);
}

//@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Alegreya&family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,500&display=swap');
@font-face {
  font-family: 'HelveticaNeueCyrLight';
  src: url('assets/fonts/HelveticaNeueCyr-Light.ttf');
  src: url('assets/fonts/HelveticaNeueCyr-Light.woff') format('woff'),
  url('assets/fonts/HelveticaNeueCyr-Light.ttf') format('truetype');
}

body {
  font-family: 'HelveticaNeueCyrLight', sans-serif;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.header-logo, .footer-logo {
  cursor: pointer;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header-block {
  h3 {
    width: max-content;
    font-family: 'HelveticaNeueCyrLight', cursive;
  }

  a {
    font-family: 'HelveticaNeueCyrLight', cursive;
    font-weight: normal !important;
    width: max-content;
  }

  img {
    width: 100px;
  }
}

.timer-block {
  background: black;
  color: white;

  .timer-block-internal {
    display: flex;
    padding: 2rem;
    height: 100vh;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;

    img {
      width: max-content;
      margin-left: 2rem;
      animation-name: rocket;
      animation-duration: 4s;
      animation-iteration-count: 1;
      animation-direction: alternate;
      display: block;
      animation-fill-mode: forwards;
      position: relative;

      ::before {
        position: absolute;
        display: none;
        right: -50vw;
      }
    }

    @keyframes rocket {
      0% {
        left: 20vw;
        top: 0;
      }

      50% {
        left: -50px;
        right: 0;
        top: -30vh;
      }

      82% {
        left: -50px;
      }

      95% {
        left: -25px;
      }

      100% {
        right: 0;
        top: 0;
        left: -25px;
      }
    }

    @media (max-width: 1170px) {
      flex-direction: column;

      img {
        margin: 2rem 0;
        width: 400px;
      }
    }

    @media (max-width: 500px) {
      flex-direction: column;

      img {
        margin: 2rem 0;
        width: 300px;
      }
    }
  }
}

.timer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  animation-name: timer;
  animation-duration: 4s;
  animation-iteration-count: 1;
  animation-direction: alternate;
  padding: 25px;

  @keyframes timer {
    80% {
      background: rgba(255, 255, 255, 0.52);
    }

    82% {
      color: var(--main-color);
      background: white;
      border-radius: 20px;
      padding: 50px;
    }

    95% {
      background: black;
      background: rgba(255, 255, 255, 0.52);
      border-radius: 20px;
      padding: 25px;
    }

    100% {
      border-radius: 20px;
      background: black;
      right: 0;
      left: 0;
      top: 0;
      padding: 25px;
    }
  }

  h1 {
    color: var(--blue);
    font-size: 2.25rem;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    margin-bottom: 1.5rem;
    font-family: 'HelveticaNeueCyrLight', cursive;
  }

  @media (max-width: 500px) {
    h1 {
      font-size: 1.8rem;
      text-align: center;
    }

    p {
      text-align: center;
    }
  }

  p {
    color: var(--text-medium);
    padding: 2rem;
  }

  button {
    font-size: 1rem;
    max-width: 160px;
    width: 100%;
    padding: 0.8rem 2rem;
    background: var(--blue);
    color: var(--white);
    border: none;
    border-radius: 0.8rem;
    cursor: pointer;
    transition: filter 0.3s;

    &:hover {
      filter: brightness(0.7);
    }
  }
}

.header-block {
  background: black;

  h3 {
    color: white;
  }

  a {
    text-decoration: none;
    color: white;
    font-weight: bold;
    cursor: pointer;
    font-size: 20px;
    white-space: nowrap;
  }
}

.timer-block-internal {
  h1 {
    font-weight: bold;
  }
}

.about-us-block {
  background: rgb(40, 44, 52);
  background: linear-gradient(164deg, rgba(40, 44, 52, 1) 0%, rgba(83, 67, 145, 1) 100%);
  color: white;
  padding: 90px 0;

  h1 {
    margin-bottom: 40px;
  }

  h5 {
    font-size: 24px;
    line-height: 1.6;
    font-family: 'HelveticaNeueCyrLight', sans-serif;
  }

  .icon-header {
    border: 2px solid white;
    padding: 10px 20px;
    border-radius: 6px;
  }
}

.icon-header {
  margin-bottom: 40px;

  svg {
    padding-right: 20px;
  }
}

.partners-block {
  background: rgb(40, 44, 52);
  padding: 90px 0;

  h1 {
    color: white;
    margin-bottom: 40px;
  }

  .carousel-block {
    height: 320px;
    display: flex;
    justify-content: center;
    align-items: start;
  }

  .icon-header {
    border-bottom: 2px solid white;
    padding: 10px 20px;
  }
}

.about-project-block {
  height: 40%;
  min-height: 400px;
  color: var(--main-color);
  background: rgba(0, 0, 0, 0.1);

  h5 {
    font-size: 24px;
    line-height: 1.6;
    font-family: 'HelveticaNeueCyrLight', sans-serif;
  }

  h1 {
    border: 2px solid var(--main-color);
    padding: 10px 20px;
    border-radius: 6px;
  }
}

.values-block {
  box-shadow: 0-1px 0 0 rgba(0, 0, 0, 0.1);
  padding: 90px 0;

  h1 {
    color: white;
  }

  .icon-header {
    border-bottom: 2px solid white;
    padding-bottom: 5px;
  }

  .values-box {
    width: calc(100% - 100px) !important;
    max-width: 1440px;

    .card {
      width: 320px;
      min-width: 320px;
      min-height: 400px;
      margin: 20px;
      border: 2px solid #e7e7e7;

      .card-body {
        min-height: 400px;
        height: 400px;

        h5 {
          font-family: 'HelveticaNeueCyrLight', sans-serif;
          font-weight: bold;
          height: 50px;
        }

        p {
          display: flex;
          align-items: center;
          height: calc(100% - 50px);
          font-family: 'Roboto', cursive;
        }
      }
    }

    .card-active {
      background: var(--main-color-light);
      color: white;
    }
  }

  .hover {
    position: relative;

    &:hover &__no-hover {
      opacity: 0;
    }

    &:hover &__hover {
      opacity: 1;
      transition: 5s;
    }

    &__hover {
      position: absolute;
      top: 0;
      opacity: 0;
    }

    &__no-hover {
      opacity: 1;
    }
  }
}

.investment-block {
  padding: 90px 0;
  background: rgba(0, 0, 0, 0.1);

  .icon-header {
    border: 2px solid var(--main-color);
    padding: 10px 20px;
    border-radius: 6px;

    svg {
      color: var(--main-color);
    }
  }

  .values-box {
    width: calc(100% - 100px) !important;
    max-width: 1440px;
  }

  h1 {
    color: var(--main-color);
  }

  .card {
    width: 400px;
    margin: 20px;
    border: 2px white solid;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: var(--main-color);
    color: white;
    border-radius: 20px;

    &:nth-child(even) {
      flex-direction: row-reverse;
    }

    .card-img-top {
      height: 75px;
    }

    .card-body {
      height: 220px;

      h5 {
        font-family: 'HelveticaNeueCyrLight', sans-serif;
        font-weight: bold;
        height: 50px;
        text-decoration: underline;
        margin: 0;
        line-height: 26px;
        letter-spacing: 1.5px;
      }

      p {
        font-family: 'Montserrat', sans-serif;
      }
    }
  }
}

.tokenomic-block {
  width: 100%;
  aspect-ratio: 2/1;
  background: rgba(0, 0, 0, 0.1);

  .bar-chart {
    width: calc(100% - 100px) !important;
    max-width: 1440px;
  }
}

h1, h2 {
  font-family: 'HelveticaNeueCyrLight', cursive;
}

.ukraine-flag {
  height: 60px;
  width: 90px;
  margin-right: 20px;

  div {
    width: 100%;
    height: 50%;
    background: yellow;
  }

  div:first-of-type {
    background: #0a53be;
  }
}

.link-list {
  display: flex;
}

@media screen and (max-width: 1024px) {
  .header-block {
    padding-top: 30px;

    div {
      justify-content: center !important;
    }

    .link-list {
      display: none !important;
    }

    .header-logo {
      flex-wrap: nowrap !important;
      width: 100%;
      cursor: pointer;

      img {
        height: 30px;
        width: 50px;
      }
    }
  }

  .timer-block-internal {
    padding: 0 !important;
    height: calc(100vh - 3rem);
  }

  .partners-block {
    width: 100%;

    .carousel {
      width: 100%;
    }
  }

  .footer-block {
    background: rgb(40, 44, 52) !important;

    .footer-box {
      width: 100%;
    }

    .footer-logo {
      flex-wrap: nowrap;
      cursor: pointer;

      img {
        height: 30px;
        width: 40px;
        padding: 0;
      }
    }

    .footer-link-list {
      flex-direction: column;
      align-items: flex-end;
    }
  }

  .icon-header {
    font-size: 22px;
  }

  .investment-block .values-box {
    width: 100vw !important;
  }

  .investment-block .card {
    width: 90% !important;

    .card-body {
      height: max-content;
    }
  }

  .footer-block {
    width: 100vw;
    padding: 0;
    margin-bottom: 20px;

    .footer-logo {
      padding: 0;
      margin: 0;
    }

    .footer-link-list {
      padding: 0;
      margin: 0;
    }
  }

  .about-us-block {
    h5 {
      padding: 0 20px;
      font-size: 20px;
    }
  }

  .about-project-block {
    .ukraine-flag {
      min-width: 35px;
      height: 25px;
      margin: 0;
    }

    h5 {
      width: 90%;
    }
  }

  h5 {
    font-size: 20px !important;
  }
}

.footer-block {
  background: rgb(40, 44, 52) !important;
}

